header {
  h1 {
    text-align: center;
    color: white;
    margin-bottom: 10px;
  }

  p {
    color: white;
    margin-bottom: 50px;
  }
}
