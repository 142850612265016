.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  background: #121214;

  p {
    color: white;
    text-align: center;
    padding: 0 15px;
  }

  .answer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .word {
      margin: 0;
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.2rem;
      @media screen and (max-width: 425px) {
        font-size: 2rem;
      }
    }

    .winning-word {
      color: #538d4e;
    }

    p {
      margin-bottom: 0;
    }
  }

  .word-input {
    display: flex;
    justify-content: center;
    width: 95vw;
    max-width: 450px;
  }

  .new-word-button {
    margin-top: 15px;
    background-color: #e1dbc3;
    cursor: pointer;
    border-radius: 8px;
    height: 30px;
    width: 150px;
    color: #222222;
    font-weight: 700;
  }

  .go-button {
    position: relative;
    background-color: #e1dbc3;
    border-radius: 8px;
    font-size: 16px;
    max-width: 300px;
    width: 80vw;
    height: 30px;
    color: #222222;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s; /* Safari */
    margin-top: 25px;
  }

  .go-button:hover {
    transition-duration: 0.1s;
    filter: brightness(85%);
  }

  .go-button:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 4px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
  }

  .go-button:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 4px;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }

  .go-button:active {
    top: 1px;
  }
}
