.letter-input-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20%;
}

input[type="text"] {
  color: #d7dadc;
  width: 15vw;
  height: 15vw;
  max-height: 70px;
  max-width: 70px;
  margin: 0 5px 5px 5px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  border: none;
  padding: 0;

  @media screen and (max-width: 425px) {
    font-size: 36px;
  }

  @media screen and (max-width: 350px) {
    font-size: 20px;
  }
}

.radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 15vw;
  max-width: 70px;

  input {
    border: 1px solid gray;
    box-sizing: border-box;
    appearance: none;
    background: white;
    width: 4vw;
    height: 4vw;
    max-width: 18px;
    max-height: 18px;
    margin: 0;
    border-radius: 0;
    &:hover {
      cursor: pointer;
    }
  }
}
